.breadcrumb-nav
	max-width: 1400px
	width: 100%
	margin-left: auto
	margin-right: auto
	display: grid
	grid-column-gap: 20px
	justify-content: center
	grid-row-gap: 50px
	padding-left: 40px
	padding-right: 40px
	grid-template-columns: repeat(4, 1fr)
	@media screen and (max-width: 800px)
		margin-bottom: 50px

.breadcrumb-box
	position: relative
	border: 1px solid #000
	padding: 40px 20px 20px 20px
	text-align: center
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	&.--not-active
		opacity: 0.3
	@media screen and (min-width: 0\0) and (min-resolution: +72dpi)
		width: 308px
		margin-right: 30px
		&:last-child
			margin-right: 0
	@media screen and (max-width: 800px)
		padding: 0
		border: none

.edit-btn
	position: absolute
	background: transparent
	border: 0
	cursor: pointer
	outline: none
	top: 2px
	right: 8px
	font-size: 12px
	color: #ee5250
	&:hover
		color: #000
	@media screen and (max-width: 800px)
		display: none

.breadcrumb-box-circle
	border: 1px solid #000
	border-radius: 50%
	height: 50px
	width: 50px
	display: flex
	justify-content: center
	align-items: center
	position: absolute
	top: 0
	left: 50%
	font-weight: 400
	font-size: 24px
	background-color: white
	transform: translate(-50%, -50%)

.breadcrumb
	line-height: 1.5
	font-weight: 500
	@media screen and (max-width: 800px)
		display: none

.tick
	//noinspection CssUnknownTarget
	background-image: url('../img/tick.svg')
	background-repeat: no-repeat
	background-position: 40% 45%
	background-size: 35px 35px
