@import 'general/form'

.login-wrapper
	font-size: 20px
	font-weight: 300
	letter-spacing: 0.7px

.total-price-extras-wrapper
	display: flex
	justify-content: center
	align-items: center
	input[type="number"]
		user-select: none
		font-size: 16px
		border: 1
		width: 70px
		margin-left: 0px
		outline: none
		&::-webkit-inner-spin-button, &::-webkit-outer-spin-button
			-webkit-appearance: none

.add-ons-grid
	display: grid
	grid-gap: 30px
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))

.add-on
	display: grid
	border-bottom: 0
	grid-template-areas: 'add-on'
	grid-template-rows: auto
	&:hover .add-on-overlay
		opacity: 1

.add-on-flex-wrapper
	grid-area: add-on
	display: flex
	flex-direction: column
	justify-self: center
	border: 1px solid #000
	border-bottom: 0
	width: 100%
	height: 100%
	padding: 20px
	text-align: center

.add-on-wrapper
	display: grid
	grid-template-areas: 'content' 'button'
	grid-template-rows: auto 50px

.add-on-icon
	width: 70px
	height: 70px
	align-self: center
	background-color: #d5d5d5
	margin-bottom: 5px

.add-on-price-wrapper
	display: flex
	justify-content: center
	align-items: center


.add-on-overlay
	grid-area: add-on
	font-size: 12px !important
	text-align: center
	color: #fff
	position: relative
	padding: 20px
	background-color: #ee5250
	opacity: 0
	width: 100%
	height: 100%
	transition: opacity 0.3s ease-out
	display: flex
	justify-content: center
	align-items: center
	border: 1px solid #000

.btn-add-on
	grid-area: button

.extra-price
	font-weight: 300

.intl-tel-input
	max-height: 45px

	label
		left: 36px
