.room-grid
	display: grid
	max-width: 1400px
	width: 100%
	padding-left: 40px
	padding-right: 40px
	grid-template-columns: 1fr 1fr
	margin-left: auto
	margin-right: auto
	@media screen and (max-width: 850px)
		grid-template-columns: 1fr
	@media screen and (max-width: 600px)
		padding-left: 30px
		padding-right: 30px

	@media screen and (min-width: 0\0) and (min-resolution: +72dpi)
		display: flex

.room-img-wrapper
	position: relative
	width: 100%
	height: 100%
	display: flex
	overflow: hidden
	transition: transform 0.5s
	background-color: #F9F9F9
	min-height: 400px

.img-room
	object-fit: cover
	height: 100%
	width: 100%
	flex-shrink: 0
	transform: translateX(0)
	transition: transform 0.5s

.img-controls-wrapper
	position: absolute
	bottom: 10px
	left: 10px
	display: flex
	@media screen and (min-width: 0\0) and (min-resolution: +72dpi)
		display: none

.img-control
	height: 12px
	width: 12px
	background-color: transparent
	border: 1px solid #fff
	border-radius: 50%
	flex-shrink: 0
	display: block
	outline: none
	padding: 0
	cursor: pointer
	&:not(:last-child)
		margin-right: 10px
	&.--active
		background-color: #fff


.room-description-wrapper
	position: relative
	width: 100%
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	min-width: 380px
	background-color: #F2F3F3
	padding: 50px 40px 30px 40px

.room-description-content
	text-align: center
	width: 100%

.room-icon-wrapper
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	flex-wrap: wrap

.icon-wrapper
	flex-shrink: 0
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	&:not(:last-child)
		margin-right: 20px

.room-icon
	width: 30px
	height: 30px
	margin-bottom: 5px
	display: flex
	justify-content: center
	align-items: center
	font-size: 20px

.icon-description
	font-weight: 100
	font-size: 14px
	text-transform: capitalize


.room-button-wrapper
	display: flex
	justify-content: center

.btn-room
	padding: 0.5em
	font-size: 16px
	font-weight: 100
	z-index: 1

.room
	height: 582px

.room + .room
	margin-top: 80px
	max-width: 1400px

.btn-headline-wrapper
	position: relative
	display: flex
	align-items: center

.btn-info
	margin-left: auto
	background-color: #000
	width: 15px
	height: 15px
	border-radius: 50%
	color: #fff
	display: flex
	justify-content: center
	align-items: center
	font-size: 12px
	border: 0
	outline: none
	cursor: pointer
	&:hover ~ .btn-info-layover
		transform: scale(1)
		pointer-events: all


.room-btn-description
	font-size: 14px
	line-height: 1
	margin-bottom: 5px

.link-gallery
	margin-top: 4px
	font-size: 14px
	color: black
	text-decoration: none
	width: auto
	transition: color 0.1s ease-out
	&:hover
		color: #ee5250
		text-decoration: underline

.no-image-wrapper
	width: 100%
	dispaly: flex
	flex-direction: column
	justify-content: center
	align-items: center
	text-align: center
	justify-self: center
	align-self: center

.no-image-text
	justify-self: center
	align-self: center
	color: #C2C2C2
	font-weight: 500

.no-rooms
	display: flex
	flex-flow: row nowrap
	background-color: #f7dfdf
	padding: 20px
	margin: 0 40px
	align-items: flex-start

	> img
		padding-right: 20px
