@charset "UTF-8";
@font-face {
  font-family: 'zokucons';
  src: url("../fonts/zoku/zokucons.eot?41605070");
  src: url("../fonts/zoku/zokucons.eot?41605070#iefix") format("embedded-opentype"), url("../fonts/zoku/zokucons.woff2?41605070") format("woff2"), url("../fonts/zoku/zokucons.woff?41605070") format("woff"), url("../fonts/zoku/zokucons.ttf?41605070") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "zokucons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
   font-size: 120%;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-arrow-down:before {
  content: '\e800'; }

/* '' */
.icon-arrow-left:before {
  content: '\e801'; }

/* '' */
.icon-arrow-right:before {
  content: '\e802'; }

/* '' */
.icon-arrow-up:before {
  content: '\e803'; }

/* '' */
.icon-baby-bed:before {
  content: '\e804'; }

/* '' */
.icon-beer-bottle:before {
  content: '\e805'; }

/* '' */
.icon-bike:before {
  content: '\e806'; }

/* '' */
.icon-bill:before {
  content: '\e807'; }

/* '' */
.icon-boxing-glove:before {
  content: '\e808'; }

/* '' */
.icon-broom:before {
  content: '\e809'; }

/* '' */
.icon-cactus:before {
  content: '\e80a'; }

/* '' */
.icon-calendar:before {
  content: '\e80b'; }

/* '' */
.icon-camera:before {
  content: '\e80c'; }

/* '' */
.icon-cereal:before {
  content: '\e80d'; }

/* '' */
.icon-check-out:before {
  content: '\e80e'; }

/* '' */
.icon-city:before {
  content: '\e80f'; }

/* '' */
.icon-clock:before {
  content: '\e810'; }

/* '' */
.icon-coffee:before {
  content: '\e811'; }

/* '' */
.icon-controller:before {
  content: '\e812'; }

/* '' */
.icon-dice:before {
  content: '\e813'; }

/* '' */
.icon-dropdown-arrow:before {
  content: '\e814'; }

/* '' */
.icon-dumbell:before {
  content: '\e815'; }

/* '' */
.icon-flipchart:before {
  content: '\e816'; }

/* '' */
.icon-gear:before {
  content: '\e817'; }

/* '' */
.icon-guitar:before {
  content: '\e818'; }

/* '' */
.icon-hamburger:before {
  content: '\e819'; }

/* '' */
.icon-invader:before {
  content: '\e81a'; }

/* '' */
.icon-iron:before {
  content: '\e81b'; }

/* '' */
.icon-key:before {
  content: '\e81c'; }

/* '' */
.icon-late-checkout:before {
  content: '\e81d'; }

/* '' */
.icon-leaf:before {
  content: '\e81e'; }

/* '' */
.icon-lightbulb:before {
  content: '\e81f'; }

/* '' */
.icon-map:before {
  content: '\e820'; }

/* '' */
.icon-lotion:before {
  content: '\e821'; }

/* '' */
.icon-message:before {
  content: '\e822'; }

/* '' */
.icon-microphone:before {
  content: '\e823'; }

/* '' */
.icon-pacman:before {
  content: '\e824'; }

/* '' */
.icon-padlock:before {
  content: '\e825'; }

/* '' */
.icon-panpipes:before {
  content: '\e826'; }

/* '' */
.icon-paper-roll:before {
  content: '\e827'; }

/* '' */
.icon-tree:before {
  content: '\e828'; }

/* '' */
.icon-towel:before {
  content: '\e829'; }

/* '' */
.icon-towel-roll:before {
  content: '\e82a'; }

/* '' */
.icon-television:before {
  content: '\e82b'; }

/* '' */
.icon-suitcase:before {
  content: '\e82c'; }

/* '' */
.icon-storage-box:before {
  content: '\e82d'; }

/* '' */
.icon-spork:before {
  content: '\e82e'; }

/* '' */
.icon-stopwatch:before {
  content: '\e82f'; }

/* '' */
.icon-zoku-u:before {
  content: '\e830'; }

/* '' */
.icon-zoku-x:before {
  content: '\e831'; }

/* '' */
.icon-sock:before {
  content: '\e832'; }

/* '' */
.icon-social-bubble:before {
  content: '\e833'; }

/* '' */
.icon-scooter:before {
  content: '\e834'; }

/* '' */
.icon-mattress:before {
  content: '\e835'; }

/* '' */
.icon-book:before {
  content: '\e836'; }

/* '' */
.icon-faq:before {
  content: '\e837'; }

/* '' */
.icon-arrow-alt-down:before {
  content: '\e838'; }

/* '' */
.icon-arrow-alt-left:before {
  content: '\e839'; }

/* '' */
.icon-arrow-alt-right:before {
  content: '\e83a'; }

/* '' */
.icon-arrow-alt-up:before {
  content: '\e83b'; }

/* '' */
.icon-home:before {
  content: '\e83c'; }

/* '' */
.icon-iron-1:before {
  content: '\e83d'; }

/* '' */
.icon-tie:before {
  content: '\e83e'; }

/* '' */
.icon-guide:before {
  content: '\e83f'; }

/* '' */
.icon-coathanger:before {
  content: '\e840'; }

/* '' */
.icon-contactless:before {
  content: '\e841'; }

/* '' */
.icon-cooking-pot:before {
  content: '\e842'; }

/* '' */
.icon-female:before {
  content: '\e843'; }

/* '' */
.icon-key-card:before {
  content: '\e844'; }

/* '' */
.icon-lotus-flower:before {
  content: '\e845'; }

/* '' */
.icon-male:before {
  content: '\e846'; }

/* '' */
.icon-paper-waste:before {
  content: '\e847'; }

/* '' */
.icon-trash:before {
  content: '\e848'; }

/* '' */
.icon-stones:before {
  content: '\e849'; }

/* '' */
.icon-zoku-k:before {
  content: '\e84a'; }

/* '' */
.icon-zoku-o:before {
  content: '\e84b'; }

/* '' */
.icon-square:before {
  content: '\e84c'; }

/* '' */
.icon-socket:before {
  content: '\e84d'; }

/* '' */
.icon-zoku-logo:before {
  content: '\e84e'; }

/* '' */
.icon-zoku-z:before {
  content: '\e84f'; }

/* '' */
.icon-shower:before {
  content: '\e850'; }

/* '' */
.icon-electric-bike:before {
  content: '\e851'; }

/* '' */
.icon-heart:before {
  content: '\e852'; }

/* '' */
.icon-share:before {
  content: '\e853'; }

/* '' */
.icon-safe:before {
  content: '\e854'; }

/* '' */
.icon-kitchen:before,
.icon-whisk:before {
  content: '\e855'; }

/* '' */
.icon-washing-machine:before {
  content: '\e856'; }

/* '' */
.icon-rolling-pin:before {
  content: '\e857'; }

/* '' */
.icon-reception-bell:before {
  content: '\e858'; }

/* '' */
.icon-video:before {
  content: '\e859'; }

/* '' */
.icon-undies:before {
  content: '\e85a'; }

/* '' */
.icon-printer:before {
  content: '\e85b'; }

/* '' */
.icon-plus:before {
  content: '\e85c'; }

/* '' */
.icon-tripadvisor:before {
  content: '\e85d'; }

/* '' */
.icon-disabled:before {
  content: '\e85e'; }

/* '' */
.icon-mail:before {
  content: '\e85f'; }

/* '' */
.icon-pin:before {
  content: '\e860'; }

/* '' */
.icon-search:before {
  content: '\e861'; }

/* '' */
.icon-window:before {
  content: '\e862'; }

/* '' */
.icon-wifi:before {
  content: '\e863'; }

/* '' */
.icon-bed:before {
  content: '\e864'; }

/* '' */
.icon-one-person:before {
  content: '\e865'; }

/* '' */
.icon-1-2-adults:before,
.icon-one-to-two-people:before {
  content: '\e866'; }

/* '' */
.icon-table:before {
  content: '\e867'; }

/* '' */
.icon-bunk-bed:before {
  content: '\e868'; }

/* '' */
.icon-obstructed-view:before {
  content: '\e869'; }

/* '' */
.icon-art-swapping:before {
  content: '\e86a'; }

/* '' */
.icon-parking-and-charging:before {
  content: '\e86b'; }

/* '' */
.icon-register:before {
  content: '\e86c'; }

/* '' */
.icon-taxi:before {
  content: '\e86d'; }

/* '' */
.icon-virtual-assistant:before {
  content: '\e86e'; }

/* '' */
