.backdrop
	position: fixed
	background-color: rgba(0,0,0,.5)
	top: 0
	bottom: 0
	left: 0
	right: 0
	z-index: 100
	pointer-events: none
	opacity: 0
	transition: opacity 0.3s
	display: flex
	justify-content: center
	align-items: center
	overflow-y: scroll
	max-height: 100vh
	&.active-backdrop
		opacity: 1
		display: flex
		pointer-events: all

.datepicker
	z-index:  200 !important

.datepicker-headline-wrapper
	text-align: center
	font-family: sans-serif

.timeslot-headline-wrapper
	text-align: center
	width: 100%

.datepicker-wrapper
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	grid-area: datepicker
	width: 50%
	flex-shrink: 0

.date-picker-day
	font-size: 45px
	font-weight: 400
	line-height: 1.2

.backdrop-content
	max-width: 600px
	width: 100%
	background-color: #fff
	padding: 20px
	display: none
	.active-backdrop &
		display: block

.date-timepicker
	display: flex

.timeslot-wrapper
	display: grid
	padding-top: 10px
	justify-content: center
	grid-template-columns: 1fr
	grid-gap: 20px
	height: 80%
	grid-auto-rows: 60px
	overflow-y: scroll


.timeslot-divider
	width: 100%
	border-bottom: 1px solid #000

.checkbox-wrapper-timeslot
	display: flex
	padding: 5px
	position: relative

.checkbox-timeslot, .checkbox-timeslot-all
	position: absolute
	pointer-events: none
	opacity: 0
	&:checked ~ .checkboxFaux
		background-image: url('../img/zoku/checkbox_checked.png')
		background-repeat: no-repeat
		background-size: contain
		border-color: #ee5250
	&:checked ~ .checkboxFaux-select-all
		background-color:  #ee5250
		border-color:  #ee5250
		color: white

.checkboxFaux
	width: 20px
	height: 20px
	border: 1px solid #000

.select-all-wrapper
	pointer-events: all
	&.--not-active
		pointer-events: none

.checkboxFaux-select-all
	witdh: 100%
	padding: 10px
	display: flex
	justify-content: center
	align-items: center
	margin-top: 20px
	border: 1px solid #000
	cursor: pointer
	.--not-active &
		border-color: #EFEFEF
		color: #9B9B9B
		background-color: #EFEFEF



.timeslot-times
	margin-left: 50px

.calendar-svg
	background-image: url('../img/zoku/calendar.svg')
	background-repeat: no-repeat
	background-position: 40% 45%

.label-checkbox-timeslot
	cursor: pointer
	display: flex
	width: 100%
	height: 60px
	padding-left: 15px
	padding-right: 15px
	border: 1px solid #000
	align-items: center
	margin-bottom: 20px
	&:last-child
		margin-bottom: 0
	&.--not-available
		pointer-events: none
		background-color: #EFEFEF
		border-color: #EFEFEF
		color: #9B9B9B
		& > .checkboxFaux
			border: 1px solid #EFEFEF

.timerpicker-btn
	grid-column: 1 / -1


.table-condensed th
	padding: 5px !important

.table-condensed
	font-size: 15px !important

.table-condensed  td
	padding: 5px !important

.table-condensed .active
	background-color:  #ee5250 !important
	border-color: #ee5250 !important
