@import 'datepicker'

.container
	position: relative

.request-room-wrapper
	max-width: 800px
	margin-left: auto
	margin-right: auto
	width: 100%
	display: grid
	grid-column-gap: 20px
	grid-row-gap: 20px
	justify-content: center
	grid-template-columns: repeat(2, 1fr)
	grid-template-rows: 190px
	max-height: 100vh
	overflow: scroll
	@media screen and (max-width: 450px)
		grid-template-rows: repeat(2, 190px)
		grid-template-columns: 1fr
	@media screen and (min-width:0\0) and (min-resolution: +72dpi)
		display: flex

label[for="meeting-date"]
	cursor: pointer

.request-room_header
	width: 100%
	flex-grow: 0
	text-align: center
	border: 1px solid #000
	border-bottom: 0

.request-room_content
	flex-grow: 1
	display: flex
	position: relative
	flex-direction: column
	justify-content: center
	align-items: center
	border: 1px solid #000
	user-select: none

.request-room_content
	input[type="number"]
		user-select: none
		&::-webkit-inner-spin-button, &::-webkit-outer-spin-button
			-webkit-appearance: none

.counter-wrapper
	display: flex
	width: 100%
	justify-content: space-between
	padding-left: 20px
	padding-right: 20px
	@media screen and (max-width: 600px)
		padding-left: 20px
		padding-right: 20px

.counter-btn
	font-size: 25px
	border: 0
	width: 20px
	outline: none
	display: flex
	padding: 20px
	cursor: pointer
	justify-content: center
	align-items: center
	-webkit-appearance: none
	background-color: transparent

.guest-counter
	font-family: 'GothamRoundedBook', sans-serif
	font-size: 45px
	text-align: center
	border: 0
	width: 120px

.booking-input
	font-weight: 200
	width: 100%
	height: 50px
	border: 1px solid #000
	font-size: 18px
	padding-left: 40px
	padding-right: 40px
	@media screen and (max-width: 600px)
		padding-left: 20px
		padding-right: 20px

.request-room-box
	display: flex
	flex-direction: column
	@media screen and (min-width:0\0) and (min-resolution: +72dpi)
		width: 200px
		margin-right: 20px
		flex-shrink: 0
		&:last-child
			margin-right: 0

.guest-notification
	background-color: #f7dfdf
	padding: 20px
	justify-content: center
	align-items: center
	display: none
	@media screen and (max-width: 600px)
		flex-direction: column
	&.--notification-active
			display: flex

.notification-img
	background-image: url('../img/zoku/icon-notification.png')
	background-repeat: no-repeat
	background-size: 50px 50px
	flex-shrink: 0
	width: 50px
	height: 50px
	margin-right: 20px
	@media screen and (max-width: 600px)
		margin-right: 0
		margin-bottom: 20px

.notification-text
	text-align: left
	@media screen and (max-width: 600px)
		text-align: center

.datepicker-input
	pointer-events: none
	position: absolute
	opacity: 0

.date-error
	color: red
	position: absolute
	bottom: -25px
	left: 5px
	font-size: 12px
	opacity: 0
	&.--active-error
		opacity: 1

.label-checkbox-timeslot-all
	display: block
