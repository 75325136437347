@charset "UTF-8";
@font-face {
  font-family: "GothamRoundedLight";
  src: url(../fonts/zoku/gotham-rounded-light.otf);
}
@font-face {
  font-family: "GothamRoundedBook";
  src: url(../fonts/zoku/gotham-rounded-book.otf);
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.container {
  max-width: 1400px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
}
.container.w-1200 {
  max-width: 1200px;
}
.container.w-1000 {
  max-width: 1000px;
}
.container.w-800 {
  max-width: 800px;
}
.container.w-600 {
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.main-wrapper {
  position: relative;
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  display: none;
}
.wrapper.active-wrapper {
  position: relative;
  opacity: 1;
  display: flex;
}
.wrapper.was-active-wrapper {
  top: 0;
  display: none;
  position: absolute;
}

.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.loading-backdrop.now-loading {
  display: flex;
  z-index: 100;
  opacity: 1;
}

body {
  line-height: 2;
  font-size: 16px;
  font-weight: 500;
  font-variant-ligatures: none;
  font-family: "GothamRoundedLight", sans-serif;
}
@media screen and (max-width: 1030px) {
  body {
    font-size: 14px;
  }
}

p {
  line-height: 28px;
  font-size: 16px;
  font-weight: 300;
  font-variant-ligatures: none;
  letter-spacing: 0.5px;
  line-height: 26px;
}
@media screen and (max-width: 1030px) {
  p {
    font-size: 14px;
  }
}

.header-1 {
  font-size: 45px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 2px;
  font-family: "GothamRoundedBook", sans-serif;
}
@media screen and (max-width: 1450px) {
  .header-1 {
    font-size: 39px;
  }
}
@media screen and (max-width: 1030px) {
  .header-1 {
    font-size: 34px;
  }
}

.header-2 {
  font-size: 34px;
  font-weight: 500;
  line-height: 1.4;
  font-family: "GothamRoundedBook", sans-serif;
}
@media screen and (max-width: 1450px) {
  .header-2 {
    font-size: 32px;
  }
}
@media screen and (max-width: 1030px) {
  .header-2 {
    font-size: 28px;
  }
}

.header-3 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 2px;
  font-family: "GothamRoundedBook", sans-serif;
}
@media screen and (max-width: 1450px) {
  .header-3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1030px) {
  .header-3 {
    font-size: 26px;
  }
}

.header-4 {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 2px;
  font-family: "GothamRoundedBook", sans-serif;
}
@media screen and (max-width: 1450px) {
  .header-4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1030px) {
  .header-4 {
    font-size: 18px;
  }
}

.bold {
  font-weight: bold;
  font-family: "GothamRoundedBook", sans-serif;
}

.light {
  font-weight: 300;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-uppercase {
  text-transform: none;
}

strong {
  font-family: "GothamRoundedBook", sans-serif;
}

.strikethrough {
  text-decoration: line-through;
  color: #7f7f7f;
}

.italic {
  font-style: italic;
}

.link {
  color: black;
  transition: color 0.1s ease-out;
}
.link:hover {
  color: #ee5250;
  text-decoration: underline;
}

.text-medium {
  font-family: "GothamRoundedBook", sans-serif;
}

.text-light {
  font-weight: 100;
}

.text-small {
  font-size: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .mt-10 {
    margin-top: 5px;
  }
  .mb-10 {
    margin-bottom: 5px;
  }
  .pt-10 {
    padding-top: 5px;
  }
  .pb-10 {
    padding-bottom: 5px;
  }
}
.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .mt-20 {
    margin-top: 10px;
  }
  .mb-20 {
    margin-bottom: 10px;
  }
  .pt-20 {
    padding-top: 10px;
  }
  .pb-20 {
    padding-bottom: 10px;
  }
}
.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

@media screen and (max-width: 500px) {
  .mt-30 {
    margin-top: 15px;
  }
  .mb-30 {
    margin-bottom: 15px;
  }
  .pt-30 {
    padding-top: 15px;
  }
  .pb-30 {
    padding-bottom: 15px;
  }
}
.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

@media screen and (max-width: 500px) {
  .mt-40 {
    margin-top: 20px;
  }
  .mb-40 {
    margin-bottom: 20px;
  }
  .pt-40 {
    padding-top: 20px;
  }
  .pb-40 {
    padding-bottom: 20px;
  }
}
.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

@media screen and (max-width: 500px) {
  .mt-50 {
    margin-top: 25px;
  }
  .mb-50 {
    margin-bottom: 25px;
  }
  .pt-50 {
    padding-top: 25px;
  }
  .pb-50 {
    padding-bottom: 25px;
  }
}
.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

@media screen and (max-width: 500px) {
  .mt-60 {
    margin-top: 30px;
  }
  .mb-60 {
    margin-bottom: 30px;
  }
  .pt-60 {
    padding-top: 30px;
  }
  .pb-60 {
    padding-bottom: 30px;
  }
}
.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

@media screen and (max-width: 500px) {
  .mt-70 {
    margin-top: 35px;
  }
  .mb-70 {
    margin-bottom: 35px;
  }
  .pt-70 {
    padding-top: 35px;
  }
  .pb-70 {
    padding-bottom: 35px;
  }
}
.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media screen and (max-width: 500px) {
  .mt-80 {
    margin-top: 40px;
  }
  .mb-80 {
    margin-bottom: 40px;
  }
  .pt-80 {
    padding-top: 40px;
  }
  .pb-80 {
    padding-bottom: 40px;
  }
}
.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

@media screen and (max-width: 500px) {
  .mt-90 {
    margin-top: 45px;
  }
  .mb-90 {
    margin-bottom: 45px;
  }
  .pt-90 {
    padding-top: 45px;
  }
  .pb-90 {
    padding-bottom: 45px;
  }
}
.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

@media screen and (max-width: 500px) {
  .mt-100 {
    margin-top: 50px;
  }
  .mb-100 {
    margin-bottom: 50px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
}
.mt-110 {
  margin-top: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.pt-110 {
  padding-top: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

@media screen and (max-width: 500px) {
  .mt-110 {
    margin-top: 55px;
  }
  .mb-110 {
    margin-bottom: 55px;
  }
  .pt-110 {
    padding-top: 55px;
  }
  .pb-110 {
    padding-bottom: 55px;
  }
}
.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

@media screen and (max-width: 500px) {
  .mt-120 {
    margin-top: 60px;
  }
  .mb-120 {
    margin-bottom: 60px;
  }
  .pt-120 {
    padding-top: 60px;
  }
  .pb-120 {
    padding-bottom: 60px;
  }
}
.mt-130 {
  margin-top: 130px;
}

.mb-130 {
  margin-bottom: 130px;
}

.pt-130 {
  padding-top: 130px;
}

.pb-130 {
  padding-bottom: 130px;
}

@media screen and (max-width: 500px) {
  .mt-130 {
    margin-top: 65px;
  }
  .mb-130 {
    margin-bottom: 65px;
  }
  .pt-130 {
    padding-top: 65px;
  }
  .pb-130 {
    padding-bottom: 65px;
  }
}
.mt-140 {
  margin-top: 140px;
}

.mb-140 {
  margin-bottom: 140px;
}

.pt-140 {
  padding-top: 140px;
}

.pb-140 {
  padding-bottom: 140px;
}

@media screen and (max-width: 500px) {
  .mt-140 {
    margin-top: 70px;
  }
  .mb-140 {
    margin-bottom: 70px;
  }
  .pt-140 {
    padding-top: 70px;
  }
  .pb-140 {
    padding-bottom: 70px;
  }
}
.mt-150 {
  margin-top: 150px;
}

.mb-150 {
  margin-bottom: 150px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

@media screen and (max-width: 500px) {
  .mt-150 {
    margin-top: 75px;
  }
  .mb-150 {
    margin-bottom: 75px;
  }
  .pt-150 {
    padding-top: 75px;
  }
  .pb-150 {
    padding-bottom: 75px;
  }
}
.btn {
  border-radius: 0;
  background: transparent;
  appearance: none;
  color: #000;
  padding: 0.75em 1em;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  width: auto;
  display: inline-block;
  letter-spacing: 0.1em;
  position: relative;
  text-align: center;
  min-width: 12em;
  z-index: 1;
  border: 1px solid #000;
  transition: background-color 0.3s ease-out, border 0.3s ease-out;
}
.btn.--small {
  display: inline-block;
}
.btn.wide-100 {
  width: 100%;
}
.btn.--min-height {
  padding: 0.5em 1em;
}
.btn:focus {
  outline: 0;
  color: #fff;
}
.btn:hover {
  color: #fff;
  background: #000;
}
.btn.red {
  color: #fff;
  border: 1px solid #ee5250;
  background: #ee5250;
}
.btn.red:hover {
  background: #000;
  border: 1px solid #000;
}
.btn.black {
  color: #fff;
  border: 1px solid #000;
  background: #000;
}
.btn.black:hover {
  background: #ee5250;
  border: 1px solid #ee5250;
}
.btn.red-wh {
  color: #fff;
  border: 1px solid #ee5250;
  background: #ee5250;
}
.btn:disabled {
  background-color: #bbb;
  border-color: #bbb;
}
.btn:disabled:hover {
  background-color: #bbb;
  border-color: #bbb;
}

.breadcrumb-nav {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-column-gap: 20px;
  justify-content: center;
  grid-row-gap: 50px;
  padding-left: 40px;
  padding-right: 40px;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 800px) {
  .breadcrumb-nav {
    margin-bottom: 50px;
  }
}

.breadcrumb-box {
  position: relative;
  border: 1px solid #000;
  padding: 40px 20px 20px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.breadcrumb-box.--not-active {
  opacity: 0.3;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .breadcrumb-box {
    width: 308px;
    margin-right: 30px;
  }
  .breadcrumb-box:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .breadcrumb-box {
    padding: 0;
    border: none;
  }
}

.edit-btn {
  position: absolute;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  top: 2px;
  right: 8px;
  font-size: 12px;
  color: #ee5250;
}
.edit-btn:hover {
  color: #000;
}
@media screen and (max-width: 800px) {
  .edit-btn {
    display: none;
  }
}

.breadcrumb-box-circle {
  border: 1px solid #000;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: 400;
  font-size: 24px;
  background-color: white;
  transform: translate(-50%, -50%);
}

.breadcrumb {
  line-height: 1.5;
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .breadcrumb {
    display: none;
  }
}

.tick {
  background-image: url("../img/tick.svg");
  background-repeat: no-repeat;
  background-position: 40% 45%;
  background-size: 35px 35px;
}

@font-face {
  font-family: "zokucons";
  src: url("../fonts/zoku/zokucons.eot?41605070");
  src: url("../fonts/zoku/zokucons.eot?41605070#iefix") format("embedded-opentype"), url("../fonts/zoku/zokucons.woff2?41605070") format("woff2"), url("../fonts/zoku/zokucons.woff?41605070") format("woff"), url("../fonts/zoku/zokucons.ttf?41605070") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
[class^=icon-]:before, [class*=" icon-"]:before {
  font-family: "zokucons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow-down:before {
  content: "\e800";
}

/* '' */
.icon-arrow-left:before {
  content: "\e801";
}

/* '' */
.icon-arrow-right:before {
  content: "\e802";
}

/* '' */
.icon-arrow-up:before {
  content: "\e803";
}

/* '' */
.icon-baby-bed:before {
  content: "\e804";
}

/* '' */
.icon-beer-bottle:before {
  content: "\e805";
}

/* '' */
.icon-bike:before {
  content: "\e806";
}

/* '' */
.icon-bill:before {
  content: "\e807";
}

/* '' */
.icon-boxing-glove:before {
  content: "\e808";
}

/* '' */
.icon-broom:before {
  content: "\e809";
}

/* '' */
.icon-cactus:before {
  content: "\e80a";
}

/* '' */
.icon-calendar:before {
  content: "\e80b";
}

/* '' */
.icon-camera:before {
  content: "\e80c";
}

/* '' */
.icon-cereal:before {
  content: "\e80d";
}

/* '' */
.icon-check-out:before {
  content: "\e80e";
}

/* '' */
.icon-city:before {
  content: "\e80f";
}

/* '' */
.icon-clock:before {
  content: "\e810";
}

/* '' */
.icon-coffee:before {
  content: "\e811";
}

/* '' */
.icon-controller:before {
  content: "\e812";
}

/* '' */
.icon-dice:before {
  content: "\e813";
}

/* '' */
.icon-dropdown-arrow:before {
  content: "\e814";
}

/* '' */
.icon-dumbell:before {
  content: "\e815";
}

/* '' */
.icon-flipchart:before {
  content: "\e816";
}

/* '' */
.icon-gear:before {
  content: "\e817";
}

/* '' */
.icon-guitar:before {
  content: "\e818";
}

/* '' */
.icon-hamburger:before {
  content: "\e819";
}

/* '' */
.icon-invader:before {
  content: "\e81a";
}

/* '' */
.icon-iron:before {
  content: "\e81b";
}

/* '' */
.icon-key:before {
  content: "\e81c";
}

/* '' */
.icon-late-checkout:before {
  content: "\e81d";
}

/* '' */
.icon-leaf:before {
  content: "\e81e";
}

/* '' */
.icon-lightbulb:before {
  content: "\e81f";
}

/* '' */
.icon-map:before {
  content: "\e820";
}

/* '' */
.icon-lotion:before {
  content: "\e821";
}

/* '' */
.icon-message:before {
  content: "\e822";
}

/* '' */
.icon-microphone:before {
  content: "\e823";
}

/* '' */
.icon-pacman:before {
  content: "\e824";
}

/* '' */
.icon-padlock:before {
  content: "\e825";
}

/* '' */
.icon-panpipes:before {
  content: "\e826";
}

/* '' */
.icon-paper-roll:before {
  content: "\e827";
}

/* '' */
.icon-tree:before {
  content: "\e828";
}

/* '' */
.icon-towel:before {
  content: "\e829";
}

/* '' */
.icon-towel-roll:before {
  content: "\e82a";
}

/* '' */
.icon-television:before {
  content: "\e82b";
}

/* '' */
.icon-suitcase:before {
  content: "\e82c";
}

/* '' */
.icon-storage-box:before {
  content: "\e82d";
}

/* '' */
.icon-spork:before {
  content: "\e82e";
}

/* '' */
.icon-stopwatch:before {
  content: "\e82f";
}

/* '' */
.icon-zoku-u:before {
  content: "\e830";
}

/* '' */
.icon-zoku-x:before {
  content: "\e831";
}

/* '' */
.icon-sock:before {
  content: "\e832";
}

/* '' */
.icon-social-bubble:before {
  content: "\e833";
}

/* '' */
.icon-scooter:before {
  content: "\e834";
}

/* '' */
.icon-mattress:before {
  content: "\e835";
}

/* '' */
.icon-book:before {
  content: "\e836";
}

/* '' */
.icon-faq:before {
  content: "\e837";
}

/* '' */
.icon-arrow-alt-down:before {
  content: "\e838";
}

/* '' */
.icon-arrow-alt-left:before {
  content: "\e839";
}

/* '' */
.icon-arrow-alt-right:before {
  content: "\e83a";
}

/* '' */
.icon-arrow-alt-up:before {
  content: "\e83b";
}

/* '' */
.icon-home:before {
  content: "\e83c";
}

/* '' */
.icon-iron-1:before {
  content: "\e83d";
}

/* '' */
.icon-tie:before {
  content: "\e83e";
}

/* '' */
.icon-guide:before {
  content: "\e83f";
}

/* '' */
.icon-coathanger:before {
  content: "\e840";
}

/* '' */
.icon-contactless:before {
  content: "\e841";
}

/* '' */
.icon-cooking-pot:before {
  content: "\e842";
}

/* '' */
.icon-female:before {
  content: "\e843";
}

/* '' */
.icon-key-card:before {
  content: "\e844";
}

/* '' */
.icon-lotus-flower:before {
  content: "\e845";
}

/* '' */
.icon-male:before {
  content: "\e846";
}

/* '' */
.icon-paper-waste:before {
  content: "\e847";
}

/* '' */
.icon-trash:before {
  content: "\e848";
}

/* '' */
.icon-stones:before {
  content: "\e849";
}

/* '' */
.icon-zoku-k:before {
  content: "\e84a";
}

/* '' */
.icon-zoku-o:before {
  content: "\e84b";
}

/* '' */
.icon-square:before {
  content: "\e84c";
}

/* '' */
.icon-socket:before {
  content: "\e84d";
}

/* '' */
.icon-zoku-logo:before {
  content: "\e84e";
}

/* '' */
.icon-zoku-z:before {
  content: "\e84f";
}

/* '' */
.icon-shower:before {
  content: "\e850";
}

/* '' */
.icon-electric-bike:before {
  content: "\e851";
}

/* '' */
.icon-heart:before {
  content: "\e852";
}

/* '' */
.icon-share:before {
  content: "\e853";
}

/* '' */
.icon-safe:before {
  content: "\e854";
}

/* '' */
.icon-kitchen:before,
.icon-whisk:before {
  content: "\e855";
}

/* '' */
.icon-washing-machine:before {
  content: "\e856";
}

/* '' */
.icon-rolling-pin:before {
  content: "\e857";
}

/* '' */
.icon-reception-bell:before {
  content: "\e858";
}

/* '' */
.icon-video:before {
  content: "\e859";
}

/* '' */
.icon-undies:before {
  content: "\e85a";
}

/* '' */
.icon-printer:before {
  content: "\e85b";
}

/* '' */
.icon-plus:before {
  content: "\e85c";
}

/* '' */
.icon-tripadvisor:before {
  content: "\e85d";
}

/* '' */
.icon-disabled:before {
  content: "\e85e";
}

/* '' */
.icon-mail:before {
  content: "\e85f";
}

/* '' */
.icon-pin:before {
  content: "\e860";
}

/* '' */
.icon-search:before {
  content: "\e861";
}

/* '' */
.icon-window:before {
  content: "\e862";
}

/* '' */
.icon-wifi:before {
  content: "\e863";
}

/* '' */
.icon-bed:before {
  content: "\e864";
}

/* '' */
.icon-one-person:before {
  content: "\e865";
}

/* '' */
.icon-1-2-adults:before,
.icon-one-to-two-people:before {
  content: "\e866";
}

/* '' */
.icon-table:before {
  content: "\e867";
}

/* '' */
.icon-bunk-bed:before {
  content: "\e868";
}

/* '' */
.icon-obstructed-view:before {
  content: "\e869";
}

/* '' */
.icon-art-swapping:before {
  content: "\e86a";
}

/* '' */
.icon-parking-and-charging:before {
  content: "\e86b";
}

/* '' */
.icon-register:before {
  content: "\e86c";
}

/* '' */
.icon-taxi:before {
  content: "\e86d";
}

/* '' */
.icon-virtual-assistant:before {
  content: "\e86e";
}

/* '' */
.room-grid {
  display: grid;
  max-width: 1400px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 850px) {
  .room-grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 600px) {
  .room-grid {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .room-grid {
    display: flex;
  }
}

.room-img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  transition: transform 0.5s;
  background-color: #F9F9F9;
  min-height: 400px;
}

.img-room {
  object-fit: cover;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  transform: translateX(0);
  transition: transform 0.5s;
}

.img-controls-wrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .img-controls-wrapper {
    display: none;
  }
}

.img-control {
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  flex-shrink: 0;
  display: block;
  outline: none;
  padding: 0;
  cursor: pointer;
}
.img-control:not(:last-child) {
  margin-right: 10px;
}
.img-control.--active {
  background-color: #fff;
}

.room-description-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 380px;
  background-color: #F2F3F3;
  padding: 50px 40px 30px 40px;
}

.room-description-content {
  text-align: center;
  width: 100%;
}

.room-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.icon-wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-wrapper:not(:last-child) {
  margin-right: 20px;
}

.room-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.icon-description {
  font-weight: 100;
  font-size: 14px;
  text-transform: capitalize;
}

.room-button-wrapper {
  display: flex;
  justify-content: center;
}

.btn-room {
  padding: 0.5em;
  font-size: 16px;
  font-weight: 100;
  z-index: 1;
}

.room {
  height: 582px;
}

.room + .room {
  margin-top: 80px;
  max-width: 1400px;
}

.btn-headline-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-info {
  margin-left: auto;
  background-color: #000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 0;
  outline: none;
  cursor: pointer;
}
.btn-info:hover ~ .btn-info-layover {
  transform: scale(1);
  pointer-events: all;
}

.room-btn-description {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
}

.link-gallery {
  margin-top: 4px;
  font-size: 14px;
  color: black;
  text-decoration: none;
  width: auto;
  transition: color 0.1s ease-out;
}
.link-gallery:hover {
  color: #ee5250;
  text-decoration: underline;
}

.no-image-wrapper {
  width: 100%;
  dispaly: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-self: center;
  align-self: center;
}

.no-image-text {
  justify-self: center;
  align-self: center;
  color: #C2C2C2;
  font-weight: 500;
}

.no-rooms {
  display: flex;
  flex-flow: row nowrap;
  background-color: #f7dfdf;
  padding: 20px;
  margin: 0 40px;
  align-items: flex-start;
}
.no-rooms > img {
  padding-right: 20px;
}

.form {
  width: 100%;
}

.form-grid {
  max-width: 1000px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 600px) {
  .form-grid {
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    grid-template-columns: 1fr;
  }
}

.details-input {
  height: 45px;
  border: 1px solid #000;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  outline: none;
  width: 100%;
  color: #333;
}
.details-input:placeholder-shown ~ .input-label {
  font-size: 16px;
  top: 7px;
  color: #d5d5d5;
  font-weight: 100;
}
.details-input.--textarea {
  height: auto;
  resize: none;
}
.details-input.--error {
  border: 1px solid red;
}

.details-select + .input-label {
  font-size: 16px;
  top: 7px;
  color: #d5d5d5;
  font-weight: 100;
}

.details-select + .input-label.--on-input {
  font-size: 12px;
  top: -23px;
  color: #333;
  font-weight: 300;
}

.input-field {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .input-field {
    margin-bottom: 40px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.input-field.--checkbox {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-label {
  font-size: 12px;
  top: -23px;
  color: #333;
  font-weight: 300;
  position: absolute;
  padding-left: 16px;
  letter-spacing: 0.5px;
  left: 0;
  pointer-events: none;
  transition: font-size 0.3s, top 0.3s, color 0.3s, font-weight 0.3s;
}

.select-box {
  position: relative;
  border: 1px solid black;
  display: block;
  width: 100%;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.select-box:after {
  content: "";
  position: absolute;
  pointer-events: none;
  right: 0;
  background-image: url("../img/zoku/arrow-down.png");
  background-repeat: no-repeat;
  background-size: 12px 10px;
  background-position: 0px 5px;
  width: 20px;
  height: 20px;
}

.details-select {
  font-family: "Avenir Next", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  outline: none;
  appearance: none;
  border: none;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  width: 100%;
  height: 45px;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  border-radius: 0;
  top: 0px;
}
.details-select:focus ~ .input-label {
  font-size: 12px;
  top: -23px;
  color: #333;
  font-weight: 300;
}
.details-select.--error {
  border: 1px solid red;
}

.checkbox-wrapper {
  display: flex;
}

.checkbox-input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.checkbox-input:checked ~ .label-checkbox {
  background-image: url("../img/zoku/checkbox_checked.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-color: #ee5250;
}

.label-checkbox {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid #000;
  flex-shrink: 0;
}
.label-checkbox.--error {
  border: 1px solid red;
}

.label-checkbox-text {
  color: #333;
  letter-spacing: 0.5px;
}

.type-error {
  position: relative;
  color: red;
  left: 15px;
  font-size: 12px;
  display: none;
}
.type-error.--checkbox {
  left: 0;
}
.type-error.--show-error {
  display: block;
}

.confirm-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  grid-template-areas: "summary cancel-policy" "summary deposit-policy" "summary payment-info";
}
@media screen and (max-width: 850px) {
  .confirm-grid {
    grid-template-areas: "summary summary" "cancel-policy deposit-policy " "payment-info payment-info";
  }
}
@media screen and (max-width: 550px) {
  .confirm-grid {
    grid-template-columns: 1fr;
    grid-template-areas: "summary" "cancel-policy" "deposit-policy " "payment-info";
  }
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .confirm-grid {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.bookin-summary-wrapper {
  grid-area: summary;
  padding: 20px 0 20px 0;
  background-color: #F2F3F3;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .bookin-summary-wrapper {
    margin-bottom: 20px;
  }
}

.cancel-policy-wrapper {
  grid-area: cancel-policy;
  padding: 20px;
  background-color: #F2F3F3;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .cancel-policy-wrapper {
    margin-bottom: 20px;
  }
}

.deposit-policy-wrapper {
  grid-area: deposit-policy;
  padding: 20px;
  background-color: #F2F3F3;
}

.payment-info-wrapper {
  grid-area: payment-info;
  padding: 20px;
  background-color: #F2F3F3;
}

.booking-summary-header {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

.booked-room-wrapper {
  width: 100%;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  padding-left: 40px;
  padding-right: 40px;
  grid-template-columns: 3fr 1fr;
}

.booked-room-price {
  justify-self: end;
}

.city-taxes-amount {
  justify-self: end;
}

.extras-amount {
  justify-self: end;
}

.total-wrapper {
  background-color: #adadad;
  width: calc(100% - 40px);
  display: grid;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.total-price-wrapper {
  justify-self: end;
  text-align: right;
}

.check-in-out-wrapper {
  display: flex;
  justify-content: center;
}

.checkin-date {
  margin-left: 5px;
}

.checkout-date {
  margin-left: 5px;
}

.guest-details-wrapper {
  position: relative;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.guest-details {
  position: relative;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.reservation-time,
.checkin-date {
  margin-left: 10px;
}

.backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  max-height: 100vh;
}
.backdrop.active-backdrop {
  opacity: 1;
  display: flex;
  pointer-events: all;
}

.datepicker {
  z-index: 200 !important;
}

.datepicker-headline-wrapper {
  text-align: center;
  font-family: sans-serif;
}

.timeslot-headline-wrapper {
  text-align: center;
  width: 100%;
}

.datepicker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: datepicker;
  width: 50%;
  flex-shrink: 0;
}

.date-picker-day {
  font-size: 45px;
  font-weight: 400;
  line-height: 1.2;
}

.backdrop-content {
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  display: none;
}
.active-backdrop .backdrop-content {
  display: block;
}

.date-timepicker {
  display: flex;
}

.timeslot-wrapper {
  display: grid;
  padding-top: 10px;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  height: 80%;
  grid-auto-rows: 60px;
  overflow-y: scroll;
}

.timeslot-divider {
  width: 100%;
  border-bottom: 1px solid #000;
}

.checkbox-wrapper-timeslot {
  display: flex;
  padding: 5px;
  position: relative;
}

.checkbox-timeslot, .checkbox-timeslot-all {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.checkbox-timeslot:checked ~ .checkboxFaux, .checkbox-timeslot-all:checked ~ .checkboxFaux {
  background-image: url("../img/zoku/checkbox_checked.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-color: #ee5250;
}
.checkbox-timeslot:checked ~ .checkboxFaux-select-all, .checkbox-timeslot-all:checked ~ .checkboxFaux-select-all {
  background-color: #ee5250;
  border-color: #ee5250;
  color: white;
}

.checkboxFaux {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
}

.select-all-wrapper {
  pointer-events: all;
}
.select-all-wrapper.--not-active {
  pointer-events: none;
}

.checkboxFaux-select-all {
  witdh: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #000;
  cursor: pointer;
}
.--not-active .checkboxFaux-select-all {
  border-color: #EFEFEF;
  color: #9B9B9B;
  background-color: #EFEFEF;
}

.timeslot-times {
  margin-left: 50px;
}

.calendar-svg {
  background-image: url("../img/zoku/calendar.svg");
  background-repeat: no-repeat;
  background-position: 40% 45%;
}

.label-checkbox-timeslot {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #000;
  align-items: center;
  margin-bottom: 20px;
}
.label-checkbox-timeslot:last-child {
  margin-bottom: 0;
}
.label-checkbox-timeslot.--not-available {
  pointer-events: none;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
  color: #9B9B9B;
}
.label-checkbox-timeslot.--not-available > .checkboxFaux {
  border: 1px solid #EFEFEF;
}

.timerpicker-btn {
  grid-column: 1/-1;
}

.table-condensed th {
  padding: 5px !important;
}

.table-condensed {
  font-size: 15px !important;
}

.table-condensed td {
  padding: 5px !important;
}

.table-condensed .active {
  background-color: #ee5250 !important;
  border-color: #ee5250 !important;
}

.backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  max-height: 100vh;
}
.backdrop.active-backdrop {
  opacity: 1;
  display: flex;
  pointer-events: all;
}

.datepicker {
  z-index: 200 !important;
}

.datepicker-headline-wrapper {
  text-align: center;
  font-family: sans-serif;
}

.timeslot-headline-wrapper {
  text-align: center;
  width: 100%;
}

.datepicker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: datepicker;
  width: 50%;
  flex-shrink: 0;
}

.date-picker-day {
  font-size: 45px;
  font-weight: 400;
  line-height: 1.2;
}

.backdrop-content {
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  display: none;
}
.active-backdrop .backdrop-content {
  display: block;
}

.date-timepicker {
  display: flex;
}

.timeslot-wrapper {
  display: grid;
  padding-top: 10px;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  height: 80%;
  grid-auto-rows: 60px;
  overflow-y: scroll;
}

.timeslot-divider {
  width: 100%;
  border-bottom: 1px solid #000;
}

.checkbox-wrapper-timeslot {
  display: flex;
  padding: 5px;
  position: relative;
}

.checkbox-timeslot, .checkbox-timeslot-all {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.checkbox-timeslot:checked ~ .checkboxFaux, .checkbox-timeslot-all:checked ~ .checkboxFaux {
  background-image: url("../img/zoku/checkbox_checked.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-color: #ee5250;
}
.checkbox-timeslot:checked ~ .checkboxFaux-select-all, .checkbox-timeslot-all:checked ~ .checkboxFaux-select-all {
  background-color: #ee5250;
  border-color: #ee5250;
  color: white;
}

.checkboxFaux {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
}

.select-all-wrapper {
  pointer-events: all;
}
.select-all-wrapper.--not-active {
  pointer-events: none;
}

.checkboxFaux-select-all {
  witdh: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #000;
  cursor: pointer;
}
.--not-active .checkboxFaux-select-all {
  border-color: #EFEFEF;
  color: #9B9B9B;
  background-color: #EFEFEF;
}

.timeslot-times {
  margin-left: 50px;
}

.calendar-svg {
  background-image: url("../img/zoku/calendar.svg");
  background-repeat: no-repeat;
  background-position: 40% 45%;
}

.label-checkbox-timeslot {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #000;
  align-items: center;
  margin-bottom: 20px;
}
.label-checkbox-timeslot:last-child {
  margin-bottom: 0;
}
.label-checkbox-timeslot.--not-available {
  pointer-events: none;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
  color: #9B9B9B;
}
.label-checkbox-timeslot.--not-available > .checkboxFaux {
  border: 1px solid #EFEFEF;
}

.timerpicker-btn {
  grid-column: 1/-1;
}

.table-condensed th {
  padding: 5px !important;
}

.table-condensed {
  font-size: 15px !important;
}

.table-condensed td {
  padding: 5px !important;
}

.table-condensed .active {
  background-color: #ee5250 !important;
  border-color: #ee5250 !important;
}

.container {
  position: relative;
}

.request-room-wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 190px;
  max-height: 100vh;
  overflow: scroll;
}
@media screen and (max-width: 450px) {
  .request-room-wrapper {
    grid-template-rows: repeat(2, 190px);
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .request-room-wrapper {
    display: flex;
  }
}

label[for=meeting-date] {
  cursor: pointer;
}

.request-room_header {
  width: 100%;
  flex-grow: 0;
  text-align: center;
  border: 1px solid #000;
  border-bottom: 0;
}

.request-room_content {
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  user-select: none;
}

.request-room_content input[type=number] {
  user-select: none;
}
.request-room_content input[type=number]::-webkit-inner-spin-button, .request-room_content input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.counter-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 600px) {
  .counter-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.counter-btn {
  font-size: 25px;
  border: 0;
  width: 20px;
  outline: none;
  display: flex;
  padding: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  background-color: transparent;
}

.guest-counter {
  font-family: "GothamRoundedBook", sans-serif;
  font-size: 45px;
  text-align: center;
  border: 0;
  width: 120px;
}

.booking-input {
  font-weight: 200;
  width: 100%;
  height: 50px;
  border: 1px solid #000;
  font-size: 18px;
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and (max-width: 600px) {
  .booking-input {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.request-room-box {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .request-room-box {
    width: 200px;
    margin-right: 20px;
    flex-shrink: 0;
  }
  .request-room-box:last-child {
    margin-right: 0;
  }
}

.guest-notification {
  background-color: #f7dfdf;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: none;
}
@media screen and (max-width: 600px) {
  .guest-notification {
    flex-direction: column;
  }
}
.guest-notification.--notification-active {
  display: flex;
}

.notification-img {
  background-image: url("../img/zoku/icon-notification.png");
  background-repeat: no-repeat;
  background-size: 50px 50px;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  .notification-img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.notification-text {
  text-align: left;
}
@media screen and (max-width: 600px) {
  .notification-text {
    text-align: center;
  }
}

.datepicker-input {
  pointer-events: none;
  position: absolute;
  opacity: 0;
}

.date-error {
  color: red;
  position: absolute;
  bottom: -25px;
  left: 5px;
  font-size: 12px;
  opacity: 0;
}
.date-error.--active-error {
  opacity: 1;
}

.label-checkbox-timeslot-all {
  display: block;
}

.thankyou-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
}
.thankyou-wrapper > p {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 3s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.form {
  width: 100%;
}

.form-grid {
  max-width: 1000px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 600px) {
  .form-grid {
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    grid-template-columns: 1fr;
  }
}

.details-input {
  height: 45px;
  border: 1px solid #000;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  outline: none;
  width: 100%;
  color: #333;
}
.details-input:placeholder-shown ~ .input-label {
  font-size: 16px;
  top: 7px;
  color: #d5d5d5;
  font-weight: 100;
}
.details-input.--textarea {
  height: auto;
  resize: none;
}
.details-input.--error {
  border: 1px solid red;
}

.details-select + .input-label {
  font-size: 16px;
  top: 7px;
  color: #d5d5d5;
  font-weight: 100;
}

.details-select + .input-label.--on-input {
  font-size: 12px;
  top: -23px;
  color: #333;
  font-weight: 300;
}

.input-field {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 72dpi) {
  .input-field {
    margin-bottom: 40px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.input-field.--checkbox {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-label {
  font-size: 12px;
  top: -23px;
  color: #333;
  font-weight: 300;
  position: absolute;
  padding-left: 16px;
  letter-spacing: 0.5px;
  left: 0;
  pointer-events: none;
  transition: font-size 0.3s, top 0.3s, color 0.3s, font-weight 0.3s;
}

.select-box {
  position: relative;
  border: 1px solid black;
  display: block;
  width: 100%;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.select-box:after {
  content: "";
  position: absolute;
  pointer-events: none;
  right: 0;
  background-image: url("../img/zoku/arrow-down.png");
  background-repeat: no-repeat;
  background-size: 12px 10px;
  background-position: 0px 5px;
  width: 20px;
  height: 20px;
}

.details-select {
  font-family: "Avenir Next", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  outline: none;
  appearance: none;
  border: none;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  width: 100%;
  height: 45px;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  border-radius: 0;
  top: 0px;
}
.details-select:focus ~ .input-label {
  font-size: 12px;
  top: -23px;
  color: #333;
  font-weight: 300;
}
.details-select.--error {
  border: 1px solid red;
}

.checkbox-wrapper {
  display: flex;
}

.checkbox-input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.checkbox-input:checked ~ .label-checkbox {
  background-image: url("../img/zoku/checkbox_checked.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-color: #ee5250;
}

.label-checkbox {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid #000;
  flex-shrink: 0;
}
.label-checkbox.--error {
  border: 1px solid red;
}

.label-checkbox-text {
  color: #333;
  letter-spacing: 0.5px;
}

.type-error {
  position: relative;
  color: red;
  left: 15px;
  font-size: 12px;
  display: none;
}
.type-error.--checkbox {
  left: 0;
}
.type-error.--show-error {
  display: block;
}

.login-wrapper {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.7px;
}

.total-price-extras-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.total-price-extras-wrapper input[type=number] {
  user-select: none;
  font-size: 16px;
  border: 1;
  width: 70px;
  margin-left: 0px;
  outline: none;
}
.total-price-extras-wrapper input[type=number]::-webkit-inner-spin-button, .total-price-extras-wrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.add-ons-grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.add-on {
  display: grid;
  border-bottom: 0;
  grid-template-areas: "add-on";
  grid-template-rows: auto;
}
.add-on:hover .add-on-overlay {
  opacity: 1;
}

.add-on-flex-wrapper {
  grid-area: add-on;
  display: flex;
  flex-direction: column;
  justify-self: center;
  border: 1px solid #000;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}

.add-on-wrapper {
  display: grid;
  grid-template-areas: "content" "button";
  grid-template-rows: auto 50px;
}

.add-on-icon {
  width: 70px;
  height: 70px;
  align-self: center;
  background-color: #d5d5d5;
  margin-bottom: 5px;
}

.add-on-price-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-on-overlay {
  grid-area: add-on;
  font-size: 12px !important;
  text-align: center;
  color: #fff;
  position: relative;
  padding: 20px;
  background-color: #ee5250;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
}

.btn-add-on {
  grid-area: button;
}

.extra-price {
  font-weight: 300;
}

.intl-tel-input {
  max-height: 45px;
}
.intl-tel-input label {
  left: 36px;
}

.intl-tel-input {
  width: 100%;
  position: relative;
  display: inline-block;
}

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.intl-tel-input .hide {
  display: none;
}

.intl-tel-input .v-hide {
  visibility: hidden;
}

.intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
}

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px;
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}
.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999;
}

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
  margin-right: 6px;
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=text], .intl-tel-input.separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
  right: auto;
  left: 0;
}

.intl-tel-input.allow-dropdown .selected-flag, .intl-tel-input.separate-dial-code .selected-flag {
  width: 46px;
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: transparent;
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table;
}

.intl-tel-input.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 input, .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel] {
  padding-left: 66px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 60px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
  padding-left: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
  width: 70px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 input, .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 74px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 68px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
  padding-left: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
  width: 78px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 input, .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 82px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
  padding-left: 92px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 86px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 input, .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel] {
  padding-left: 90px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
  padding-left: 100px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
  width: 94px;
}

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}

.iti-flag.be {
  width: 18px;
}

.iti-flag.ch {
  width: 15px;
}

.iti-flag.mc {
  width: 19px;
}

.iti-flag.ne {
  width: 18px;
}

.iti-flag.np {
  width: 13px;
}

.iti-flag.va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}
.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti-flag.af {
  height: 14px;
  background-position: -66px 0px;
}

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti-flag.al {
  height: 15px;
  background-position: -132px 0px;
}

.iti-flag.am {
  height: 10px;
  background-position: -154px 0px;
}

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti-flag.as {
  height: 10px;
  background-position: -242px 0px;
}

.iti-flag.at {
  height: 14px;
  background-position: -264px 0px;
}

.iti-flag.au {
  height: 10px;
  background-position: -286px 0px;
}

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti-flag.az {
  height: 10px;
  background-position: -352px 0px;
}

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti-flag.be {
  height: 15px;
  background-position: -440px 0px;
}

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti-flag.br {
  height: 14px;
  background-position: -680px 0px;
}

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti-flag.by {
  height: 10px;
  background-position: -790px 0px;
}

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0px;
}

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px;
}

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px;
}

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px;
}

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px;
}

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/zoku/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {
    background-image: url("../img/zoku/flags@2x.png");
  }
}
.iti-flag.np {
  background-color: transparent;
}