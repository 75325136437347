.form
	width: 100%

.form-grid
	max-width: 1000px
	width: 100%
	padding-left: 40px
	padding-right: 40px
	margin-left: auto
	margin-right: auto
	display: grid
	grid-gap: 30px
	grid-template-columns: 1fr 1fr
	@media screen and (max-width: 600px)
		margin-top: 30px
		padding-left: 30px
		padding-right: 30px
		grid-template-columns: 1fr

.details-input
	height: 45px
	border: 1px solid #000
	padding-left: 15px
	padding-right: 15px
	font-size: 18px
	outline: none
	width: 100%
	color: #333
	&:focus
	&:placeholder-shown ~ .input-label
		font-size: 16px
		top: 7px
		color: #d5d5d5
		font-weight: 100
	&.--textarea
		height: auto
		resize: none
	&.--error
		border: 1px solid red

.details-select + .input-label
	font-size: 16px
	top: 7px
	color: #d5d5d5
	font-weight: 100

.details-select + .input-label.--on-input
	font-size: 12px
	top: -23px
	color: #333
	font-weight: 300

.input-field
	position: relative
	width: 100%
	@media screen and (min-width:0\0) and (min-resolution: +72dpi)
		margin-bottom: 40px
		max-width: 500px
		margin-left: auto
		margin-right: auto
	&.--checkbox
		width: auto
		display: flex
		flex-direction: column
		justify-content: center

.input-label
	font-size: 12px
	top: -23px
	color: #333
	font-weight: 300
	position: absolute
	padding-left: 16px
	letter-spacing: 0.5px
	left: 0
	pointer-events: none
	transition: font-size 0.3s, top 0.3s, color 0.3s, font-weight 0.3s

.select-box
	position: relative
	border: 1px solid black
	display: block
	width: 100%
	background-color: white
	cursor: pointer
	display: flex
	justify-content: center
	align-items: center
	height: 45px
	&:after
		content: ""
		position: absolute
		pointer-events: none
		right: 0
		background-image: url('../img/zoku/arrow-down.png')
		background-repeat: no-repeat
		background-size: 12px 10px
		background-position: 0px 5px
		width: 20px
		height: 20px

.details-select
	font-family: "Avenir Next", "Helvetica Neue", Arial, sans-serif
	font-size: 18px
	outline: none
	appearance: none
	border: none
	display: block
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	background-color: transparent
	width: 100%
	height: 45px
	padding-right: 15px
	padding-left: 15px
	position: absolute
	cursor: pointer
	z-index: 1
	border-radius: 0
	top: 0px
	&:focus ~ .input-label
		font-size: 12px
		top: -23px
		color: #333
		font-weight: 300
	&.--error
		border: 1px solid red

.checkbox-wrapper
	display: flex

.checkbox-input
	position: absolute
	pointer-events: none
	opacity: 0
	&:checked ~ .label-checkbox
		background-image: url('../img/zoku/checkbox_checked.png')
		background-repeat: no-repeat
		background-size: contain
		border-color: #ee5250

.label-checkbox
	width: 25px
	height: 25px
	position: relative
	cursor: pointer
	margin-right: 10px
	border: 1px solid #000
	flex-shrink: 0
	&.--error
		border: 1px solid red

.label-checkbox-text
	color: #333
	letter-spacing: 0.5px

.type-error
	position: relative
	color: red
	left: 15px
	font-size: 12px
	display: none
	&.--checkbox
		left: 0
	&.--show-error
		display: block
