body
	line-height: 2
	font-size: 16px
	font-weight: 500
	font-variant-ligatures: none
	font-family: 'GothamRoundedLight', sans-serif
	@media screen and (max-width: 1030px)
		font-size: 14px

p
	line-height: 28px
	font-size: 16px
	font-weight: 300
	font-variant-ligatures: none
	letter-spacing: 0.5px
	line-height: 26px
	@media screen and (max-width: 1030px)
		font-size: 14px

.header-1
	font-size: 45px
	font-weight: 500
	line-height: 1.4
	letter-spacing: 2px
	font-family: 'GothamRoundedBook', sans-serif
	@media screen and (max-width: 1450px)
		font-size: 39px
	@media screen and (max-width: 1030px)
		font-size: 34px

.header-2
	font-size: 34px
	font-weight: 500
	line-height: 1.4
	font-family: 'GothamRoundedBook', sans-serif
	@media screen and (max-width: 1450px)
		font-size: 32px
	@media screen and (max-width: 1030px)
		font-size: 28px

.header-3
	font-size: 30px
	font-weight: 500
	line-height: 1.4
	letter-spacing: 2px
	font-family: 'GothamRoundedBook', sans-serif
	@media screen and (max-width: 1450px)
		font-size: 28px
	@media screen and (max-width: 1030px)
		font-size: 26px

.header-4
	font-size: 22px
	font-weight: 400
	line-height: 1.4
	letter-spacing: 2px
	font-family: 'GothamRoundedBook', sans-serif
	@media screen and (max-width: 1450px)
		font-size: 20px
	@media screen and (max-width: 1030px)
		font-size: 18px

.bold
	font-weight: bold
	font-family: 'GothamRoundedBook', sans-serif

.light
	font-weight: 300

.text-white
	color: #fff

.text-center
	text-align: center

.text-left
	text-align: left !important

.text-uppercase
	text-transform: uppercase

.no-uppercase
	text-transform: none

strong
	font-family: 'GothamRoundedBook', sans-serif

.strikethrough
	text-decoration: line-through
	color: #7f7f7f

.italic
	font-style: italic

.link
	color: black
	transition: color 0.1s ease-out
	&:hover
		color: #ee5250
		text-decoration: underline

.text-medium
	font-family: 'GothamRoundedBook', sans-serif

.text-light
	font-weight: 100

.text-small
	font-size: 12px
