.thankyou-wrapper
	width: 100%
	height: 100vh
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	position: fixed
	background-color: white
	top: 0
	left: 0

	& > p
		padding-left: 20px
		padding-right: 20px
		max-width: 600px
		margin-left: auto
		margin-right: auto
		font-size: 25px
		font-weight: 500
		line-height: 1.3
		text-align: center

.path
	stroke-dasharray: 1000
	stroke-dashoffset: 1000
	animation: dash 3s linear forwards


@keyframes dash
	to
		stroke-dashoffset: 0
