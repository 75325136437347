@import 'general/form'

.confirm-grid
	display: grid
	width: 100%
	grid-template-columns: 1fr 1fr
	grid-gap: 40px
	grid-template-areas: "summary cancel-policy" "summary deposit-policy" "summary payment-info"
	@media screen and (max-width: 850px)
		grid-template-areas: "summary summary" "cancel-policy deposit-policy " "payment-info payment-info"
	@media screen and (max-width: 550px)
		grid-template-columns: 1fr
		grid-template-areas: "summary" "cancel-policy" "deposit-policy " "payment-info"
	@media screen and (min-width:0\0) and (min-resolution: +72dpi)
		max-width: 600px
		margin-left: auto
		margin-right: auto

//Wrapper
.bookin-summary-wrapper
	grid-area: summary
	padding: 20px 0 20px 0
	background-color: #F2F3F3
	@media screen and (min-width:0\0) and (min-resolution: +72dpi)
		margin-bottom: 20px

.cancel-policy-wrapper
	grid-area: cancel-policy
	padding: 20px
	background-color: #F2F3F3
	@media screen and (min-width:0\0) and (min-resolution: +72dpi)
		margin-bottom: 20px

.deposit-policy-wrapper
	grid-area: deposit-policy
	padding: 20px
	background-color: #F2F3F3

.payment-info-wrapper
	grid-area: payment-info
	padding: 20px
	background-color: #F2F3F3


//Wrapper Details
.booking-summary-header
	position: relative
	padding-left: 40px
	padding-right: 40px

.booked-room-wrapper
	width: 100%
	display: grid
	grid-column-gap: 10px
	grid-row-gap: 15px
	padding-left: 40px
	padding-right: 40px
	grid-template-columns: 3fr 1fr

.booked-room-price
	justify-self: end

.city-taxes-amount
	justify-self: end

.extras-amount
	justify-self: end

.total-wrapper
	background-color: #adadad
	width: calc(100% - 40px)
	display: grid
	padding: 20px
	margin-left: auto
	margin-right: auto
	grid-row-gap: 10px
	grid-template-columns: 1fr 1fr
	grid-column-gap: 10px


.total-price-wrapper
	justify-self: end
	text-align: right

.check-in-out-wrapper
	display: flex
	justify-content: center

.checkin-date
	margin-left: 5px

.checkout-date
	margin-left: 5px

.guest-details-wrapper
	position: relative
	width: 100%
	padding-left: 40px
	padding-right: 40px
	text-align: center

.guest-details
	position: relative
	width: 100%
	padding-left: 40px
	padding-right: 40px

.reservation-time,
.checkin-date
	margin-left: 10px
