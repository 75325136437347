.btn
	border-radius: 0
	background: transparent
	appearance: none
	color: #000
	padding: 0.75em 1em
	font-weight: 300
	font-style: normal
	font-size: 18px
	text-transform: uppercase
	line-height: 1
	cursor: pointer
	width: auto
	display: inline-block
	letter-spacing: 0.1em
	position: relative
	text-align: center
	min-width: 12em
	z-index: 1
	border: 1px solid #000
	transition: background-color 0.3s ease-out, border 0.3s ease-out
	&.--small
		display: inline-block
	&.wide-100
		width: 100%
	&.--min-height
		padding: 0.5em 1em
	&:focus
		outline: 0
		color: #fff
	&:hover
		color: #fff
		background: #000
	&.red
		color: #fff
		border: 1px solid #ee5250
		background: #ee5250
		&:hover
			background: #000
			border: 1px solid #000
	&.black
		color: #fff
		border: 1px solid #000
		background: #000
		&:hover
			background: #ee5250
			border: 1px solid #ee5250
	&.red-wh
		color: #fff
		border: 1px solid #ee5250
		background: #ee5250

	&:disabled
		background-color: #bbb
		border-color: #bbb

		&:hover
			background-color: #bbb
			border-color: #bbb
