body
	overflow-x: hidden

.container
	max-width: 1400px
	width: 100%
	padding-left: 40px
	padding-right: 40px
	margin-left: auto
	margin-right: auto
	&.w-1200
		max-width: 1200px
	&.w-1000
		max-width: 1000px
	&.w-800
		max-width: 800px
	&.w-600
		max-width: 600px
	@media screen and (max-width: 600px)
		padding-left: 30px
		padding-right: 30px

.main-wrapper
	position: relative
	max-width: 1400px
	width: 100%
	margin-left: auto
	margin-right: auto

.wrapper
	flex-shrink: 0
	width: 100%
	flex-direction: column
	align-items: center
	position: absolute
	top: 0
	transition: opacity 0.3s, transform 0.3s
	opacity: 0
	display: none
	&.active-wrapper
		position: relative
		opacity: 1
		display: flex
	&.was-active-wrapper
		top: 0
		display: none
		position: absolute


.loading-backdrop
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background-color: rgba(0,0,0, 0.5)
	display: none
	justify-content: center
	align-items: center
	opacity: 0
	&.now-loading
		display: flex
		z-index: 100
		opacity: 1
